import { FiLoader as LoadingIcon } from "react-icons/fi";
import styled, { keyframes } from 'styled-components';

// Animação para o spinner
export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div`
  text-align: center;
  margin: 20px auto;
`;

export const SpinnerIcon = styled(LoadingIcon)`
  animation: ${spin} 2s linear infinite;
  font-size: 24px;
  color: #007bff;
`;

export const LoadingText = styled.div`
  color: #007bff;
  font-size: 12px;
  font-weight: bold;
`;