import React, { useState } from 'react';
import { AiOutlineLock } from 'react-icons/ai';
import { GrServer } from "react-icons/gr";
import { TbListDetails } from "react-icons/tb";
import Button from '../Button';
import { Stepper } from '../Stepper';
import { FirstStep } from './FirstStep';
import { MachineReview } from './MachineReview';
import { SecondStep } from './SecondStep';
import { ButtonContainer, ButtonWrapper, ContentContainer, StepperContainer, WizardContainer } from './style';
import { ThirdStep } from './ThirdStep';
import { FaKey } from "react-icons/fa";

export interface FormStepProps {
    handleSubmit: () => void;
    userData: any;
    handleInputChange: any;
    isLoading: boolean;
}

const MachineForm: React.FC<FormStepProps> = ({ handleSubmit, userData, handleInputChange, isLoading }) => {
    const [activeStep, setActiveStep] = useState<number>(0);

    const steps = [
        { title: 'Maquina', icon: <GrServer /> },
        { title: 'Especificações', icon: <TbListDetails /> },
        { title: 'Autenticação', icon: <FaKey /> },
        { title: 'Conclusão', icon: <AiOutlineLock /> }
    ];

    const onNext = () => {
        setActiveStep(activeStep + 1);
    };

    const onPrev = () => {
        setActiveStep(activeStep - 1);
    };

    const onFinish = () => {
        handleSubmit();
    };

    return (
        <WizardContainer>
            <StepperContainer>
                <Stepper
                    steps={steps}
                    nextStep={onNext}
                    prevStep={onPrev}
                    currentStep={activeStep}
                    seed={-1}
                />
            </StepperContainer>
            <ContentContainer>
                {activeStep === 0 && (<>
                    <FirstStep userData={userData} handleInputChange={handleInputChange} />
                </>
                )}
                {activeStep === 1 && (
                    <SecondStep userData={userData} handleInputChange={handleInputChange} />
                )}
                {activeStep === 2 && (
                    <ThirdStep userData={userData} handleInputChange={handleInputChange} />
                )}
                {activeStep === 3 && (
                    <MachineReview machine={userData} />
                )}
            </ContentContainer>
            <ButtonContainer>
                <ButtonWrapper>
                    <Button onClick={onPrev} variant='secondary'>
                        Voltar
                    </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button isLoading={isLoading} variant='primary' onClick={activeStep === steps.length - 1 ? onFinish : onNext}>
                        {activeStep === steps.length - 1 ? 'Concluir' : 'Próximo'}
                    </Button>
                </ButtonWrapper>
            </ButtonContainer>
        </WizardContainer>
    );
};

export default MachineForm;