import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { ReactComponent as LogoSVG } from "../../assets/img/LogoAzul.svg";
import * as S from "./Sidebar.styles";

interface SidebarItem {
  title: string;
  path: string;
  icon: React.ReactNode;
  subItems?: SidebarItem[];
  key?: string;
}
const SideBarData: SidebarItem[] = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FaIcons.FaHouseUser />,
  },
  {
    title: "Hospedagem",
    path: "",
    icon: <FaIcons.FaCubes />,
    key: "machine",
    subItems: [
      // {
      //   title: "Servidores",
      //   path: "/hosts",
      //   icon: <FaIcons.FaServer />,
      // },
      {
        title: "VMs Genial",
        path: "/machines",
        icon: <FaIcons.FaCube />,
      },
    ],
  },
  {
    title: "Usuários",
    path: "/users",
    icon: <FaClipboardUser />,
    key: "Usuarios",
  },
  {
    title: "Tokens",
    path: "/tokens",
    icon: <MdOutlineIntegrationInstructions />,
    key: "Tokens",
  },
  {
    title: "Sair",
    path: "/",
    icon: <FaIcons.FaArrowCircleLeft />,
  },
];

const Navbar = () => {
  const [activeSidebar, setActiveSidebar] = useState<SidebarItem | null>(null);

  const toggleSidebar = (item: SidebarItem) => {
    if (item.subItems) {
      if (activeSidebar === item) {
        setActiveSidebar(null);
      } else {
        setActiveSidebar(item);
      }
    }
  };

  return (
    <>
      <S.SidebarContainer
        className={activeSidebar ? "nav-menu active" : "nav-menu"}
      >
        <S.SidebarList className="nav-menu-items">
          <S.LogoWrapper>
            <LogoSVG />
          </S.LogoWrapper>
          <S.MenuItemsWrapper>
            {SideBarData.map((item, index) => (
              <S.SidebarItem
                key={index}
                className="nav-text"
                onClick={() => toggleSidebar(item)}
              >
                <S.SidebarLink to={item.path}>
                  {item.icon}
                  <span>{item.title}</span>
                  {item.subItems && (
                    <S.ToggleIcon>
                      {activeSidebar === item ? (
                        <FaIcons.FaChevronUp />
                      ) : (
                        <FaIcons.FaChevronDown />
                      )}
                    </S.ToggleIcon>
                  )}
                </S.SidebarLink>
                {item.subItems && (
                  <S.SidebarSublist
                    className={activeSidebar === item ? "active" : ""}
                  >
                    {item.subItems.map((subItem, subIndex) => (
                      <S.SidebarSubItem key={subIndex}>
                        <S.SidebarLink to={subItem.path}>
                          {subItem.icon}
                          <span>{subItem.title}</span>
                        </S.SidebarLink>
                      </S.SidebarSubItem>
                    ))}
                  </S.SidebarSublist>
                )}
              </S.SidebarItem>
            ))}
          </S.MenuItemsWrapper>
        </S.SidebarList>
      </S.SidebarContainer>
    </>
  );
};
export default Navbar;
