import { useEffect, useState } from "react";
import { Timer } from "../../pages/Machine/ListMachine/styel";
import Text from "../Text";

const StatusMessage = ({ duration }: any) => {
    const [messageIndex, setMessageIndex] = useState(0);
    const messages = [
        "Iniciando processo de configuração...",
        "Verificando disponibilidade de recursos...",
        "Alocando recursos na nuvem...",
        "Recursos alocados com sucesso.",
        "Iniciando a instalação do sistema operacional...",
        "Sistema operacional instalado.",
        "Aplicando configurações de segurança...",
        "Configurações de segurança aplicadas.",
        "Configurando a rede interna...",
        "Rede interna configurada.",
        "Atribuindo IPs estáticos...",
        "IPs estáticos atribuídos.",
        "Instalando softwares necessários...",
        "Softwares instalados.",
        "Configurando backups automáticos...",
        "Backups automáticos configurados.",
        "Realizando testes finais...",
        "Testes concluídos com sucesso.",
        "Finalizando configurações...",
        "Processo concluído!"
    ];

    useEffect(() => {
        const interval = duration / messages.length;
        const timer = setInterval(() => {
            setMessageIndex((prevIndex) => {
                const nextIndex = prevIndex + 1;
                return nextIndex < messages.length ? nextIndex : prevIndex;
            });
        }, interval * 1000);

        return () => clearInterval(timer);
    }, [duration, messages.length]);

    return <Timer><Text>{messages[messageIndex]}</Text></Timer>;
};

export default StatusMessage;