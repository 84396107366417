import Modal from 'react-modal';
import { CloseButton, ModalBody, ModalHeader } from './style';


interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}


export const CustomModal = ({ isOpen, onClose, title, children }: ModalProps) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    width: '700px',
                    transition: 'all .2s ease-in-out',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    zIndex: 1000
                }
            }}
        >
            <ModalHeader>
                <div>{title}</div>
                <CloseButton onClick={onClose}>&times;</CloseButton>
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            {/* <ModalFooter>
                <ActionButton onClick={onClose}>Close</ActionButton>
            </ModalFooter> */}
        </Modal>
    )
}