import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar from "./components/Navbar";
import Console from "./Console/Console";
import { Dashboard } from "./pages/Dashboard";
import { Hosts } from "./pages/Hosts";
import HostsDetail from "./pages/HostsDetail";
import Login from "./pages/Login";
import { ListMachines } from "./pages/Machine/ListMachine";
import MachileDetail from "./pages/MachineDetail";
import { Wrapper } from "./pages/MachineDetail/styles";
import { ListTokens } from "./pages/Token/ListTokens";
import { ListUsers } from "./pages/Users/ListUsers";
import { PageStructure } from "./styled/style";

const RoutesConfig = () => {

  const PrivateRoutes = () => {
    const location = useLocation();
    let logged = localStorage.getItem("accessToken");

    if (logged === undefined) {
      toast.error('Sua sessão expirou!');
      return null;
    }

    return logged ? (
      <PageStructure>
        <Navbar />
        <Wrapper>
          <Outlet />
        </Wrapper>
      </PageStructure>
    ) : (
      <Navigate to="/" replace state={{ from: location }} />
    );
  };

  const PrivateRouteNoWrapper = ({ children }: any) => {
    const location = useLocation();
    let logged = localStorage.getItem("accessToken");

    if (logged === undefined) {
      toast.error('Sua sessão expirou!');
      return null;
    }

    return logged ? (
      <Outlet />
    ) : (
      <Navigate to="/" replace state={{ from: location }} />
    );
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index={true} Component={Login} path="/" />
          <Route Component={PrivateRoutes} path="/dashboard">
            <Route Component={Dashboard} path="/dashboard" />
          </Route>
          <Route Component={PrivateRouteNoWrapper} path="/console">
            <Route Component={Console} path="/console" />
          </Route>
          <Route Component={PrivateRoutes} path="/machines/detail">
            <Route Component={MachileDetail} path="/machines/detail" />
          </Route>
          <Route Component={PrivateRoutes} path="/machines">
            <Route Component={ListMachines} path="/machines" />
          </Route>
          <Route Component={PrivateRoutes} path="/hosts">
            <Route Component={Hosts} path="/hosts" />
          </Route>
          <Route Component={PrivateRoutes} path="/hosts/detail">
            <Route Component={HostsDetail} path="/hosts/detail" />
          </Route>
          <Route Component={PrivateRoutes} path="/tokens">
            <Route Component={ListTokens} path="/tokens" />
          </Route>
          <Route Component={PrivateRoutes} path="/users">
            <Route Component={ListUsers} path="/users" />
          </Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </>
  );
};

export default RoutesConfig;
