import styled from "styled-components";

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label<{ labelColor?: string }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${({ labelColor }) => labelColor || '#344054'};
  margin-bottom: 10px;
  margin-left: 5px;
`;

export const Select = styled.select`
  box-sizing: border-box;
  padding: 10px 14px;
  width: 100%;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  &:disabled {
      opacity: 0.5;
      background-color: #ccc;
  }
`;