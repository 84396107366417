import { ServersWrapper } from "./dashboard.style";
import { api } from "../../service/api";
import { useEffect, useState } from "react";
import { Host, HostsMachineData, Machine } from "./interfaces";
import ShowHost from "../../components/ShowHost";
import { useNavigate } from "react-router-dom";

export const Dashboard = () => {
    const navigation = useNavigate();

    const [loading, setLoading] = useState(true);

    const [serverData, setServerData] = useState([] as HostsMachineData[]);

    useEffect(() => {
        fetchDashBoard();
    }, []);

    const fetchDashBoard = async () => {
        setLoading(true);
        try {
            const hosts = await fetchHosts();

            if (!hosts) return;

            const dataToShow = hosts.map((host) => {
                return {
                    inUse: host.used,
                    total: host.qtd_vms,
                    name: host.name,
                    id: host.id,
                } as HostsMachineData;
            });

            setServerData(dataToShow);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const fetchHosts = () => {
        return api
            .get("/hosts/")
            .then((response: { data: Host[] }) => {
                return response.data;
            })
            .catch((error: any) => {
                console.error(error);
            });
    };

    const redirectToHostDetail = (data: HostsMachineData) => {
        localStorage.setItem("hosts_detail", data.id);
        navigation("/hosts/detail");
    };

    return (
        <>
            <h1>Dashboard</h1>
            <ServersWrapper>
                {serverData.map((data) => {
                    return (
                        <ShowHost
                            hostData={data}
                            serverInUse={data.inUse > 0}
                            onClick={redirectToHostDetail}
                        />
                    );
                })}
            </ServersWrapper>
        </>
    );
};
