import { useEffect, useState } from "react";
import CardBox from "../../components/CardBox";
import SubLabel from "../../components/SubLabel";
import { useMyContext } from "../../context/GlobalContext";
import { api } from "../../service/api";
import { formatBytesToGigabytes, formatMegaBytesToGigabytes } from "../../shared/utils";
import { Body } from "./styles";
import { ListMachines } from "../Machine/ListMachine";

interface Server {
  id: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  name: string;
  alias: string;
  ip: string;
  memory: number;
  disk: number;
  band: number;
  qtd_vms: number;
  updated_by: number;
}

export default function HostsDetail() {
  const { profile } = useMyContext();
  console.log('Dashboard profile', profile || 'empty')

  const [loadingAction, setLoadingAction] = useState<any>(true)

  const [detail, setDetail] = useState<Server>({} as Server);

  const fetchDetail = () => {
    const serverId = localStorage.getItem('hosts_detail')
    setLoadingAction(true)
    if (!serverId) {
      window.history.back();
      return
    }
    console.log(serverId)
    api.get(`/hosts/${serverId}/`).then(response => {
      console.log(response.data, 'teste')
      setDetail(response.data);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      setLoadingAction(false)
    });
  }

  useEffect(() => {
    fetchDetail();
  }, [])

  const openRemoteConsole = () => {
    // window.open(detail?.console_access, '_blank', "width=1200, height=720, top=100, left=100");
  }

  const handleAction = (action: string) => {
    setLoadingAction(true)
    api.patch(`/vm/${detail.name}/${action}/`).then(response => {
      console.log(response.data);
      fetchDetail();
    }).catch(error => {
      fetchDetail();
      console.error(error);
    }).finally(() => {
      setLoadingAction(false)
    });
  }

  return (
    <>
      <CardBox
        title={"Detalhe do servidor"}
        buttonChildren={
          <>
            {/* <Button variant="primary" icon={<FaStar />} size="16px" isLoading={loadingAction} /> */}
            {/* <Button variant="primary" icon={<FaPlay />} size="16px" onClick={() => handleAction('start')} isLoading={loadingAction}>
              Ligar
            </Button>
            <Button variant="secondary" icon={<RiShutDownLine />} size="16px" onClick={() => handleAction('stop')} isLoading={loadingAction}>
              Desligar
            </Button> */}
          </>
        }
        children={
          <>
            {/* <SubLabel title={"Identificador"} subtitle={detail?.id} /> */}
            <SubLabel title={"Nome"} url={detail?.name} />
            {/* <SubLabel title={"Apelido"} subtitle={detail?.alias} /> */}
            {/* <SubLabel title={"Address"} url={detail?.ip} /> */}
            {/* <SubLabel title={"Memory"} subtitle={formatMegaBytesToGigabytes(detail?.memory) + ' Gb'} /> */}
            {/* <SubLabel title={"Disk"} subtitle={formatBytesToGigabytes(detail?.disk) + ' Gb'} /> */}
            <SubLabel title={"Qtde. máxima de VPS"} subtitle={detail.qtd_vms} />
            {/* <SubLabel title={"Bandwidth"} subtitle={formatMegaBytesToGigabytes(detail?.band) + ' Gb'} /> */}
          </>
        }
      />

      <Body>
        <ListMachines 
          hostId={localStorage.getItem('hosts_detail')!}
        />
      </Body>
    </>
  );
}
