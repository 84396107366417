import styled from "styled-components";

export interface TextProps {
  weight?: number;
  size?: number;
  align?: string;
  uppercase?: boolean;
  color?: string;
  textDecoration?: string;
}

const Text = styled.span<TextProps>`
  font-size: ${({ size }: TextProps) => size || 14}px;
  font-weight: ${({ weight }: TextProps) => weight || 400};
  color: ${({ color }) => color || "#000"};
  text-align: ${({ align }: TextProps) => align || "left"};
  ${({ uppercase }) => uppercase && "text-transform: uppercase;"}
  text-decoration-line: ${({ textDecoration }: TextProps) =>
    textDecoration || "none"};
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default Text;
