import { createContext, useContext, useState } from "react";

interface MyContextType {
    profile: string | null;
    updateProfile: (newData: string) => void;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

const MyContextProvider = ({ children }: any) => {
    const [profile, setProfile] =  useState('');

    const updateProfile = (newData: string) => {
        setProfile(newData);
    };

    return (
        <MyContext.Provider value={{ profile, updateProfile }}>
            {children}
        </MyContext.Provider>
    );
};

const useMyContext = () => {
    const context = useContext(MyContext);
    if (!context) {
        throw new Error("useMyContext must be used within a MyContextProvider");
    }
    return context;
};

export { MyContextProvider, useMyContext };
