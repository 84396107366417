import "./App.css";
import { MyContextProvider } from "./context/GlobalContext";
import Routes from "./routes";

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return <>
    <MyContextProvider>
      <Routes />
    </MyContextProvider>
  </>;
}
export default App;
