import { useState } from "react";
import ToggleButton from "../ToggleButton";
import { Button, Form, Input, InputLabel } from "./style";
import { LoadingSpinner } from "../Loading";
import styled, { keyframes } from "styled-components";
import { FiLoader as LoadingIcon } from "react-icons/fi";

interface TokenFormProps {
    isActive: boolean;
    setIsActive: (isActive: boolean) => void;
    tokenName: string;
    setTokenName: (tokenName: string) => void;
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const SpinnerIcon = styled(LoadingIcon)`
    animation: ${spin} 2s linear infinite;
    font-size: 16px;
    color: white;
`;

const TokenForm: React.FC<TokenFormProps> = ({
    isActive,
    setIsActive,
    tokenName,
    setTokenName,
    handleSubmit,
}) => {
    const [loading, setLoading] = useState(false);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                if(!loading){
                    setLoading(true);
                    handleSubmit(e);
                }
            }}
        >
            <InputLabel>
                Informe o nome para o token:
                <Input
                    type="text"
                    value={tokenName}
                    onChange={(e) => setTokenName(e.target.value)}
                />
            </InputLabel>
            <InputLabel>
                Status:
                <ToggleButton checked={isActive} onChange={setIsActive} />
            </InputLabel>
            <Button type="submit">
                {!loading && "Criar Token"}
                {loading && <SpinnerIcon color={"white"} />}
            </Button>
        </Form>
    );
};

export default TokenForm;
