import { Slider, ToggleInput, ToggleWrapper } from "./style";

interface ToggleButtonProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const ToggleButton = ({ checked, onChange }: ToggleButtonProps) => {
    return (
        <ToggleWrapper>
            <ToggleInput
                type="checkbox"
                checked={checked}
                onChange={e => onChange(e.target.checked)}
            />
            <Slider />
        </ToggleWrapper>
    );
};

export default ToggleButton;