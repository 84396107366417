import { FiSearch } from "react-icons/fi";
import { IconWrapper, SearchContainer, SearchInput } from "./style";

interface FilterProps {
    placeholder: string;
    setValue: (value: string) => void;
    value: string;
}

const Filter = (props: FilterProps) => {
    return (
        <SearchContainer>
            <IconWrapper>
                <FiSearch />
            </IconWrapper>
            <SearchInput autoComplete="off" name="search" placeholder={props.placeholder} onChange={(e: any) => props.setValue(e.target.value)} value={props.value} />
        </SearchContainer>
    )
}

export default Filter;


