import { FiLoader as LoadingIcon } from "react-icons/fi";
import styled, { keyframes } from "styled-components";

export const Title = styled.h4`
padding: 20px 0px 10px;
font-size: 20px;
font-weight: 600;
`

interface TableContainerProps {
  maxHeight?: string;
  minWidth?: string;
}

export const TableContainer = styled.div<TableContainerProps>`
  max-height: ${(props) => props.maxHeight || "300px"};
  min-width: ${(props) => props.minWidth || "100%"};
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  scrollbar-width: thin;
  scrollbar-color: #485184 #f5f5f5;
  &::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #485184;
    border-radius: 4px;
  }
`;

export const Table = styled.table<TableContainerProps>`
  min-width: ${(props) => props.minWidth || "100%"};
  border-collapse: collapse;
  border-spacing: 0;
  font-family: Arial, sans-serif;
`;

export const TableHeader = styled.thead`
  border-bottom: 2px solid #ddd;

  th {
    font-weight: 500;
    padding: 12px;
    text-transform: capitalize;
    text-align: center;
  }
`;

export const StickyHeader = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  border-bottom: 2px solid #ddd;

  th {
    font-weight: 500;
    padding: 12px;
    text-transform: capitalize;
    text-align: center;
  }
`;

export const TableBody = styled.tbody`
  width: 100%;
  td {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    min-width: 180px;
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
    text-align: center;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(LoadingIcon)`
  animation: ${spin} 2s linear infinite;
  font-size: 24px;
  color: #007bff;
  display: block;
  margin: 20px auto;
`;