import 'jquery';
import loadScript from 'load-script';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FadeLoading from '../assets/img/anim_loading.gif';
import { api } from '../service/api';

import MustLight from '../assets/img/header-light.png';

// Declarar o jQuery como uma variável global para o TypeScript e o linter
declare var $: any;

const Container = styled.div`
  background-color: black;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingImage = styled.img`
  height: 300px;
`;

const MessageContainer = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  padding: 0.5rem;
  display: none;
  text-align: center;
`;

const MessageText = styled.span<{ color: string }>`
  color: ${(props) => props.color};
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
`;

const CommandsContainer = styled.div`
  background-color: #27272a; // Similar to Zinc-900
  display: flex !important;
  gap: 10px;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: #27272a;
    padding: 0.5rem 1rem;
    
`

const CommandButton = styled.button`
  background-color: #3b82f6; // Similar to Blue-500
  border: 1px solid #3b82f6;
  border-radius: 0.5rem;
  color: white; // Similar to Zinc-900
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

const ConsoleContainer = styled.div`
    position: relative;
  background-color: black;
`;

const Console: React.FC = () => {
    const [link, setLink] = useState<string | null>(null);
    const [wmksInstance, setWmksInstance] = useState<any>(null);

    useEffect(() => {
        setTimeout(() => {
            const id = localStorage.getItem('server_detail');
            if (!id) {
                window.history.back();
                return;
            }
            api.get(`/console/${id}/`).then(response => {
                setLink(response.data.url);
            }).catch(error => {
                console.error(error);
                window.history.back();
            }).finally(() => {
                console.log('Console loaded');
            });
        }, 5000);
    }, []);

    useEffect(() => {
        const adjustConsoleSize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight - 102; // Pode ajustar essa lógica conforme necessário
            $('#console')
                .css('width', width)
                .css('height', height)
                .css('outline', 'none');
        };

        if (link) {
            loadScript('https://code.jquery.com/jquery-1.8.3.min.js', (err, script) => {
                if (err) {
                    console.error('Erro ao carregar o jQuery:', err);
                    return;
                }

                console.log('jQuery carregado com sucesso');

                loadScript('https://code.jquery.com/ui/1.8.16/jquery-ui.min.js', (err, script) => {
                    if (err) {
                        console.error('Erro ao carregar o jQuery UI:', err);
                        return;
                    }

                    console.log('jQuery UI carregado com sucesso');

                    loadScript('https://cdn.jsdelivr.net/npm/vmware-wmks@1.0.0/wmks.min.js', (err, script) => {
                        if (err) {
                            console.error('Erro ao carregar o WMKS:', err);
                            return;
                        }

                        console.log('WMKS carregado com sucesso');

                        if (window.WMKS) {
                            const instance = window.WMKS.createWMKS('console');
                            setWmksInstance(instance);

                            adjustConsoleSize();

                            instance.register('connectionstatechange', (event: any, data: any) => {
                                console.log('Connection state change:', data.state);

                                if (data.state === window.WMKS.CONST.ConnectionState.CONNECTING) {
                                    console.log('Connecting...');
                                    $('#connecting').show();
                                }
                                if (data.state === window.WMKS.CONST.ConnectionState.CONNECTED) {
                                    console.log('Connected');
                                    $('#connecting').hide();
                                    $('#connected').show();
                                    $('#commands').show();
                                }
                                if (data.state === window.WMKS.CONST.ConnectionState.DISCONNECTED) {
                                    console.log('Disconnected');
                                    $('#connecting').hide();
                                    $('#connected').hide();
                                    $('#disconnected').show();
                                }
                            });

                            instance.register('error', (event: any) => {
                                console.error('WMKS error:', event);
                            });

                            $('#cad').click(() => {
                                instance.sendCAD();
                            });

                            $('#fsn').click(() => {
                                instance.enterFullScreen();
                            });

                            try {
                                instance.connect(link);
                            } catch (error) {
                                console.error('Erro ao conectar ao WMKS:', error);
                            }
                        } else {
                            console.error('WMKS library not loaded');
                        }
                    });
                });
            });
        }

        window.addEventListener('resize', adjustConsoleSize);

        return () => {
            window.removeEventListener('resize', adjustConsoleSize);
        };
    }, [link]);

    return (
        <>
            {(!link) && (
                <Container>
                    <LoadingImage src={FadeLoading} alt="Loading" />
                </Container>
            )}
            {link && (
                <div>
                    <MessageContainer id="connecting" bgColor="#164e63"> {/* Similar to Cyan-900 */}
                        <MessageText color="#22d3ee"> {/* Similar to Cyan-500 */}
                            Conectando...
                        </MessageText>
                    </MessageContainer>

                    <MessageContainer id="connected" bgColor="#065f46"> {/* Similar to Green-900 */}
                        <MessageText color="#34d399"> {/* Similar to Green-500 */}
                            Conectado com sucesso!
                        </MessageText>
                    </MessageContainer>

                    <MessageContainer id="disconnected" bgColor="#7f1d1d"> {/* Similar to Red-900 */}
                        <MessageText color="#f87171"> {/* Similar to Red-500 */}
                            Desconectado!
                        </MessageText>
                    </MessageContainer>

                    <HeaderContainer>
                        <img src={MustLight} alt="Must Light" style={{ height: '50px' }} />

                        <CommandsContainer id="commands">
                            <CommandButton id="cad">Ctrl+Alt+Del</CommandButton>
                            <CommandButton id="fsn">Tela Cheia</CommandButton>
                        </CommandsContainer>
                    </HeaderContainer>

                    <ConsoleContainer>
                        <div id="console" style={{ backgroundColor: 'black', width: '100%' }}></div>
                    </ConsoleContainer>
                </div>
            )}
        </>
    );
};

export default Console;
