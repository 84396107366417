import styled from "styled-components";

export const ToggleWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 34px;
  height: 16px;
`;

export const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 1.5px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }

  ${ToggleInput}:checked + & {
    background-color: #2196F3;
  }

  ${ToggleInput}:checked + &:before {
    transform: translateX(18px);
  }
`;
