import React from "react";
import * as CB from "./CardBox.styles";

interface CardBoxProps {
  children?: React.ReactNode;
  title: string;
  status?: React.ReactNode;
  buttonChildren?: React.ReactNode;
  bodybutton?: React.ReactNode;
  content?: React.ReactNode;
}

export default function CardBox({
  title,
  children,
  buttonChildren,
  bodybutton,
  content,
  status
}: CardBoxProps) {
  return (
    <CB.Wrapper>
      <CB.WrapperTop>
        <CB.TextTitle><CB.TextTitleWrapper>{title} {status && status}</CB.TextTitleWrapper></CB.TextTitle>
        <CB.ButtonArea>{buttonChildren && buttonChildren}</CB.ButtonArea>
      </CB.WrapperTop>
      {children && <CB.BodyComponent>{children}</CB.BodyComponent>}
      {bodybutton && <CB.BodyButton>{bodybutton}</CB.BodyButton>}
      {content && content}
    </CB.Wrapper>
  );
}
