import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 36px;
  background-color: white;
`

export const WrapperTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const TextTitleWrapper = styled.div`
  display: flex;
  gap: 10px;
`

export const TextTitle = styled.span`
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  flex: 3;
`
export const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  gap:10px;
  flex: 1;
`

export const BodyComponent = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: auto;
  grid-gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const BodyButton = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: auto;
  grid-gap: 20px;
`