import React from "react";
import { SubTitle, Title, URL, Wrapper } from "./SubLabel.styles";

interface Sublabelprops {
  title: string;
  subtitle?: string | number;
  url?: string;
  href?: string;
}

export default function SubLabel({
  title,
  subtitle,
  url,
  href,
}: Sublabelprops) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
      <URL href={href}>{url}</URL>
    </Wrapper>
  );
}
