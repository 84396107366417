import { HostsMachineData } from '../../pages/Dashboard/interfaces';
import ServerAnimation from './components/ServerAnimation';
import { Block, ServerShape, Status, Info, InfoItem, Label, Value } from './style';

const ServerBlock = ({
  serverInUse,
  hostData,
  onClick
}: {
  serverInUse?: boolean, 
  hostData: HostsMachineData,
  onClick: (data: HostsMachineData) => void;
}) => {
  return (
    <Block
      onClick={() => onClick?.(hostData)}
    >
      <ServerShape>
        <ServerAnimation serverInUse={serverInUse}/>
        <Status>{hostData.inUse}/{hostData.total}</Status>
      </ServerShape>
      <Info>
        <InfoItem>
          <Value>{hostData.name}</Value>
        </InfoItem>
      </Info>
    </Block>
  );
};

export default ServerBlock;
