import { Input } from "../../Input/Input";
import { FormWrapper, LineContent } from "../shared/style";

export interface UserStepProps {
    handleInputChange: any;
    userData: any;
    refLoading: any;
}

export const UserFirstStep: React.FC<UserStepProps> = ({ userData, handleInputChange }) => {

    return (
        <FormWrapper>
            <LineContent>
                <Input
                    label={"Primeiro nome"}
                    name="first_name"
                    id="first_name"
                    labelColor="#000"
                    value={userData?.first_name}
                    placeholder={"Insira seu primeiro nome"}
                    type={"text"}
                    onChange={handleInputChange}
                />
                <Input
                    label={"Último nome"}
                    name="last_name"
                    id="last_name"
                    value={userData?.last_name}
                    labelColor="#000"
                    placeholder={"Insira seu Último nome"}
                    type={"text"}
                    onChange={handleInputChange}
                />

            </LineContent>
            <LineContent>
                <Input
                    label={"Login"}
                    name="username"
                    id="login"
                    value={userData?.username}
                    labelColor="#000"
                    placeholder={"Insira seu login"}
                    type={"login"}
                    onChange={handleInputChange}
                />
                <Input
                    label={"Email"}
                    name="email"
                    id="login"
                    value={userData?.email}
                    labelColor="#000"
                    placeholder={"Insira seu email"}
                    type={"email"}
                    onChange={handleInputChange}
                />
            </LineContent>
            <LineContent>
                <Input
                    label={"Senha"}
                    name="password"
                    value={userData?.password}
                    labelColor="#000"
                    placeholder={"Insira seu Senha"}
                    type={"password"}
                    onChange={handleInputChange}
                />
                <Input
                    label={"Repita Sua Senha"}
                    name="repassword"
                    value={userData?.repassword}
                    labelColor="#000"
                    placeholder={"Repita sua Senha"}
                    type={"password"}
                    onChange={handleInputChange}
                />
            </LineContent>
        </FormWrapper>
    );
}