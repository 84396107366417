import { useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { RiShutDownLine } from "react-icons/ri";
import { VscRemoteExplorer } from "react-icons/vsc";
import Button from "../../components/Button";
import CardBox from "../../components/CardBox";
import { StatusIndicator } from "../../components/StatusIndicator";
import SubLabel from "../../components/SubLabel";
import { useMyContext } from "../../context/GlobalContext";
import { api } from "../../service/api";
import { formatMegaBytesToGigabytes, formatTeraToGigaBytes } from "../../shared/utils";
import { Body, Title } from "./styles";


export default function MachileDetail() {
  const { profile } = useMyContext();
  console.log('Dashboard profile', profile || 'empty')

  const [loadingAction, setLoadingAction] = useState<any>(true)

  const [detail, setDetail] = useState<any>({});

  const fetchDetail = () => {
    const serverId = localStorage.getItem('server_detail')
    setLoadingAction(true)
    if (!serverId) {
      window.history.back();
      return
    }
    api.get(`/servers/${serverId}/`).then(response => {
      setDetail(response.data);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      setLoadingAction(false)
    });
  }

  useEffect(() => {
    fetchDetail();
  }, [])

  const openRemoteConsole = () => {
    localStorage.setItem('server_detail', detail.name)
    setLoadingAction(true)
    setTimeout(() => {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const url = `${protocol}//${host}/console`;
      window.open(url, '_blank', "width=1200,height=720,top=100,left=100");
      setLoadingAction(false);
    }, 1000)
  }

  const handleAction = (action: string) => {
    setLoadingAction(true)
    api.patch(`/vm/${detail.name}/${action}/`).then(response => {
      console.log(response.data);
      fetchDetail();
    }).catch(error => {
      fetchDetail();
      console.error(error);
    }).finally(() => {
      setLoadingAction(false)
    });
  }

  return (
    <>
      <CardBox
        title={"Detalhe da Máquina"}
        status={<StatusIndicator status={detail?.status} />}
        buttonChildren={
          <>
            {/* <Button variant="primary" icon={<FaStar />} size="16px" isLoading={loadingAction} /> */}
            <Button variant="primary" icon={<FaPlay />} size="16px" onClick={() => handleAction('start')} isLoading={loadingAction}>
              Ligar
            </Button>
            <Button variant="secondary" icon={<RiShutDownLine />} size="16px" onClick={() => handleAction('stop')} isLoading={loadingAction}>
              Desligar
            </Button>
          </>
        }
        children={
          <>
            {/* <SubLabel title={"Identificador"} subtitle={detail?.id} /> */}
            <SubLabel title={"Usuário"} url={detail?.client || '-'} />
            <SubLabel title={"Cluster"} subtitle={detail?.hosted_by} />
            <SubLabel title={"IP LAN"} url={detail?.ip} />
            <SubLabel title={"IP Público"} url={detail?.public_ip} />
            <SubLabel title={"Template"} subtitle={"Windows 2019 64"} />
            <SubLabel title={"Memory"} subtitle={formatMegaBytesToGigabytes(detail?.memory) + ' GB'} />
            <SubLabel title={"Disco"} subtitle={formatTeraToGigaBytes(detail?.disk) + ' GB'} />
            <SubLabel title={"Processadores"} subtitle={"1 core"} />
            <SubLabel title={"Largura de Banda"} subtitle={formatMegaBytesToGigabytes(detail?.band) + ' GB'} />
          </>
        }
      />

      <Body>
        <Title>Acesso Remoto</Title>

        <Button variant="primary" icon={<VscRemoteExplorer />} size="16px" onClick={openRemoteConsole} isLoading={loadingAction}>
          Acessar Máquina Remota
        </Button>
      </Body >
    </>
  );
}
