import { useEffect, useMemo, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { FiEye } from 'react-icons/fi';
import { useNavigate } from "react-router-dom";
import CardBox from "../../components/CardBox";
import { CustomModal } from "../../components/CustomModal";
import CustomTable from "../../components/CustomTable";
import Filter from "../../components/Filter";
import MachineForm from "../../components/MachineForm";
import StatusMessage from "../../components/StatusMessage";
import { api } from "../../service/api";
import { Container, Text, Timer, Value, ViewButton } from "./styel";

interface IHost {
    id: string;
    name: string;
    alias: string;
    ip: string;
    memory: number;
    disk: number;
    band: number;
    created_at: string;
    updated_at: string;
    qtd_vms: number;
    created_by: number;
    updated_by: number;
}

export const Hosts = () => {
    const navigation = useNavigate();

    const [servers, setServers] = useState<IHost[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [syncLoading, setSyncLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [machineData, setMachineData] = useState<any>({});
    const [formLoading, setFormLoading] = useState(false);
    const [creatingMachine, setCreatingMachine] = useState(false);

    const fetchHosts = () => {
        setLoading(true);
        api.get('/hosts/').then((response: any) => {
            setServers(response.data);
        }).catch((error: any) => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'name',
            },
            {
                Header: 'IP',
                accessor: 'ip',
            },
            {
                Header: 'Criado Em',
                accessor: 'created_at',
                Cell: ({ value }: any) => <span>{value}</span>
            },
            {
                Header: 'Ações',
                Cell: ({ row }: any) => (
                    <div style={{ textAlign: 'center' }}>
                        <ViewButton onClick={() => {
                            localStorage.setItem('hosts_detail', row.original.id.toString());
                            navigation('/hosts/detail');
                        }} title="Visualizar">
                            <FiEye />
                        </ViewButton>
                    </div>
                )
            },
        ],
        []
    );

    useEffect(() => {
        fetchHosts();
    }, []);

    const filteredData = useMemo(() => {
        console.log(servers.filter((item) => {
            return Object.values(item).some((value) => {
                return String(value).toLowerCase().includes(searchValue.toLowerCase());
            });
        }))
        return servers.filter((item) => {
            return Object.values(item).some((value) => {
                return String(value).toLowerCase().includes(searchValue.toLowerCase());
            });
        });
    }, [servers, searchValue]);


    const handleSync = () => {
        setSyncLoading(true);

        api.get('/sync/').then((response: any) => {
            fetchHosts();
        }).catch((error: any) => {
            console.error(error);
        }).finally(() => {
            setSyncLoading(false);
        });
    }

    const handleCreate = () => {
        setIsOpenModal(true);
    }

    const handleSubmit = () => {
        setIsOpenModal(false);
        setCreatingMachine(true);
        console.log('Criando máquina 66666455:', machineData);
        let data = {
            name: machineData.name,
            ip: machineData.ip,
            based: machineData.based,
            password: machineData.password
        }

        api.post('/vcenter/create-server/', data).then((response: any) => {
            console.log(response.data);
        })
        .catch((error: any) => console.error(error));
    }

    const handleInputChange = (e: any) => {
        console.log(e, 'Objeto de update')
        setMachineData({ ...machineData, [e.target.name]: e.target.value });
    }

    const secondsToMinutesFormat = (seconds: number) => {
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;

        let formattedMinutes = minutes.toString().padStart(2, '0');
        let formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const interativeRenderTime = ({ remainingTime }: any) => {
        if (remainingTime === 0) {
            return <Timer>Concluindo...</Timer>;
        }

        return (
            <Timer>
                <Text>Restam</Text>
                <Value>{secondsToMinutesFormat(remainingTime)}</Value>
                <Text>segundos</Text>
            </Timer>
        );
    }

    return (
        <>
            <CustomModal
                title="Aguarde a criação da VM e não feche a janela!"
                isOpen={creatingMachine}
                onClose={() => { }}
                children={
                    <Container>
                        <CountdownCircleTimer
                            isPlaying
                            duration={900}
                            colors={["#004777", "#F7B801", "#58ca0c", "#58ca0c"]}
                            colorsTime={[300, 180, 90, 0]}
                            onComplete={() => {
                                setCreatingMachine(false);
                                handleSync();
                                return { shouldRepeat: false, delay: 1 }
                            }}
                        >
                            {interativeRenderTime}
                        </CountdownCircleTimer>
                        <StatusMessage duration={900} />
                    </Container>
                }
            />
            <CustomModal
                title="Criar nova VM"
                isOpen={isOpenModal}
                onClose={() => { setIsOpenModal(false) }}
                children={<MachineForm
                    handleSubmit={handleSubmit}
                    userData={machineData}
                    handleInputChange={handleInputChange}
                    isLoading={formLoading}
                />}
            />
            <Filter setValue={setSearchValue} value={searchValue} placeholder="Pesquise por IP, Status, Nome, Data de Criação e etc.." />
            <CardBox
                title="Lista de Servidores"
                content={
                    <CustomTable maxHeight="calc(100vh - 300px)"
                        isLoading={loading}
                        columns={columns}
                        data={filteredData}
                        columnMinWidths={{}} />
                }
            />
        </>
    );
};