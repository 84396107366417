import * as I from "./Input.styles";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  type?: string;
  id?: string;
  ref?: any;
  labelColor?: string;
}

export const Input = ({ label, type, placeholder, id, labelColor, ref, ...rest }: InputProps) => {
  return (
    <I.InputWrapper style={{ ...rest.style }}>
      <label htmlFor={label} style={{ marginBottom: '10px', marginLeft: '5px', color: labelColor ? labelColor : '#fff' }}>{label}</label>
      <input type={type} ref={ref} placeholder={placeholder} {...rest} />
    </I.InputWrapper>
  );
};
