import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

`

export const RightArea = styled.div`
    display: flex;
    width: 100%;
`

export const LeftArea = styled.div`
    display: flex;
    width: 100%;
`

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  background-color: #0463e3;
  align-items: center;
  justify-content: center;
`;

export const LoginConteiner = styled.div`
  display: flex;
  flex: 1;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;

    color: white;
  }

  span {
    color: white;
  }
`;

export const LogoImageContainer= styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`

export const Form = styled.div`
  flex-direction: column;
  gap: 20px;
  width: 350px; 

  padding: 15px 0;

  @media screen and (max-width: 768px) {
    padding: 10px 20px;
  }

  @media screen and (min-width: 768px) {
    padding: 30px 40px;
  }

  background: transparent;
  border: 2px solid #1D262F33;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px; 
  backdrop-filter: blur(5px);
  
  a {
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: white;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  align-items: center;
  justify-content: space-between;
`;

export const TextArea =  styled.span`
  a{
    color: #63BFE2;
  }
`

export const ForgetPassLink = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;

  a{
    color: #63BFE2;
  }
`