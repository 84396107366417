import { DropdownWrapper, Label, Select } from "./style";

interface DropdownProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label?: string;
    id?: string;
    labelColor?: string;
    options: Array<{ value: string; label: string; disabled?: boolean }>;
}

export const Dropdown = ({ label, id, labelColor, options, ...rest }: DropdownProps) => {
    return (
        <DropdownWrapper>
            {label && (
                <Label htmlFor={id} labelColor={labelColor}>
                    {label}
                </Label>
            )}
            <Select id={id} {...rest}>
                {options.map((option) => (
                    <option key={option.value} value={option.value} disabled={option.disabled}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </DropdownWrapper>
    );
};