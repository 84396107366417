import styled from 'styled-components'


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.span`
  font-weight: bold;
  padding-bottom: 8px;
`

export const SubTitle = styled.span`
`

export const URL = styled.a`

`
